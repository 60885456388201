import React, { Component } from 'react';
import { bool, func, instanceOf, object, oneOfType, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { pathByRouteName } from '../../util/routes';
import {
  ensureCurrentUser,
  ensureUser,
  ensureTransaction,
  ensureStripeCustomer,
  ensurePaymentMethodCard,
} from '../../util/data';
import { dateFromLocalToAPI, minutesBetween } from '../../util/dates';

import { parse } from '../../util/urlHelpers';
import {
  isTransactionInitiateAmountTooLowError,
  isTransactionInitiateMissingStripeAccountError,
  isTransactionInitiateBookingTimeNotAvailableError,
  isTransactionChargeDisabledError,
  isTransactionZeroPaymentError,
  transactionInitiateOrderStripeErrors,
} from '../../util/errors';
import { txIsPaymentPending, txIsPaymentExpired } from '../../util/transaction';
import {
  Logo,
  NamedLink,
  NamedRedirect,
  Page,
  Order,
  WarningAcceptTerms
} from '../../components';
import { StripePaymentForm } from '../../forms';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  handleCardPayment,
  handleP24Payment,
  retrievePaymentIntent,
} from '../../ducks/stripe.duck';
import { savePaymentMethod } from '../../ducks/paymentMethods.duck';

import {
  initiateOrder,
  setInitialValues,
  speculateTransaction,
  stripeCustomer,
  confirmPayment,
  confirmP24Payment,
  updateProfile,
} from './CheckoutPage.duck';
import { storeData, storedData, clearData } from '../../util/checkoutSessionHelpers';
import css from './CheckoutPage.css';
import OrderSummary from './OrderSummary/OrderSummary';
import PromoCode from './PromoCode/PromoCode';
import { beginCheckoutEvent } from '../../analytics/gaEvents';

const STORAGE_KEY = 'CheckoutPage';

// Stripe PaymentIntent statuses, where user actions are already completed
// https://stripe.com/docs/payments/payment-intents/status
const STRIPE_PI_USER_ACTIONS_DONE_STATUSES = ['processing', 'requires_capture', 'succeeded'];

// Payment charge options
const ONETIME_PAYMENT = 'ONETIME_PAYMENT';
const PAY_AND_SAVE_FOR_LATER_USE = 'PAY_AND_SAVE_FOR_LATER_USE';
const USE_SAVED_CARD = 'USE_SAVED_CARD';

const paymentFlow = (selectedPaymentMethod, saveAfterOnetimePayment) => {
  // Payment mode could be 'replaceCard', but without explicit saveAfterOnetimePayment flag,
  // we'll handle it as one-time payment
  return selectedPaymentMethod === 'defaultCard'
    ? USE_SAVED_CARD
    : saveAfterOnetimePayment
      ? PAY_AND_SAVE_FOR_LATER_USE
      : ONETIME_PAYMENT;
};

const checkIsPaymentExpired = existingTransaction => {
  return txIsPaymentExpired(existingTransaction)
    ? true
    : txIsPaymentPending(existingTransaction)
      ? minutesBetween(existingTransaction.attributes.lastTransitionedAt, new Date()) >= 15
      : false;
};

export class CheckoutPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageData: {},
      dataLoaded: false,
      submitting: false,
      validatingPromoCode: false,
      promoCode: '',
    };
    this.stripe = null;

    this.onStripeInitialized = this.onStripeInitialized.bind(this);
    this.loadInitialData = this.loadInitialData.bind(this);
    this.handlePaymentIntent = this.handlePaymentIntent.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (window) {
      this.loadInitialData();
    }
  }

  /**
   * Load initial data for the page
   *
   * Since the data for the checkout is not passed in the URL (there
   * might be lots of options in the future), we must pass in the data
   * some other way. Currently the ListingPage sets the initial data
   * for the CheckoutPage's Redux store.
   *
   * For some cases (e.g. a refresh in the CheckoutPage), the Redux
   * store is empty. To handle that case, we store the received data
   * to window.sessionStorage and read it from there if no props from
   * the store exist.
   *
   * This function also sets of fetching the speculative transaction
   * based on this initial data.
   */
  loadInitialData() {
    const {
      bookingData,
      bookingDates,
      listing,
      listings,
      provider,
      transaction,
      fetchSpeculatedTransaction,
      fetchStripeCustomer,
      history,
      totalNumberOfOrders,
    } = this.props;

    const { prolongFor, end } = parse(this.props.location.search);

    if (prolongFor && end) {

      return fetchSpeculatedTransaction(
        {
          prolongFor,
          end
        },
        false
      ).then(res => {
        const transaction = res.data.data;
        // bookingDates,
        // transaction,
        // listings,
        // provider,

        beginCheckoutEvent({
          currency: transaction.attributes.payinTotal.currency,
          value: transaction.attributes.payinTotal.amount / 100,
          items: listings.map(l => ({
            id: l.listing.id.uuid,
            name: l.listing.attributes.title,
            category: l.listing.attributes.businessCategory,
            provider: provider.attributes.name,
            price: l.listing.attributes.price.amount / 100,
            quantity: l.attributes.amount,
          })),
        })

        this.setState({ dataLoaded: true, pageData: { bookingData, bookingDates, listing, transaction: null, listings, provider, totalNumberOfOrders } });
      })
    }

    // Fetch currentUser with stripeCustomer entity
    // Note: since there's need for data loading in "componentWillMount" function,
    //       this is added here instead of loadData static function.
    fetchStripeCustomer();

    // Browser's back navigation should not rewrite data in session store.
    // Action is 'POP' on both history.back() and page refresh cases.
    // Action is 'PUSH' when user has directed through a link
    // Action is 'REPLACE' when user has directed through login/signup process
    const hasNavigatedThroughLink = history.action === 'PUSH' || history.action === 'REPLACE';

    const hasDataInProps =
      !!(bookingDates && listings && provider.id.uuid) && hasNavigatedThroughLink;
    if (hasDataInProps) {
      // Store data only if data is passed through props and user has navigated through a link.
      storeData(bookingData, bookingDates, listing, transaction, listings, STORAGE_KEY);
    }

    // NOTE: stored data can be empty if user has already successfully completed transaction.
    const pageData = hasDataInProps
      ? { bookingData, bookingDates, listing, transaction, listings, provider, totalNumberOfOrders }
      : storedData(STORAGE_KEY);

    // Check if a booking is already created according to stored data.
    const tx = pageData ? pageData.transaction : null;
    const isBookingCreated = tx && tx.booking && tx.booking.id;

    const shouldFetchSpeculatedTransaction =
      pageData &&
      pageData.listings &&
      pageData.provider &&
      pageData.bookingDates &&
      pageData.bookingDates.bookingStart &&
      pageData.bookingDates.bookingEnd &&
      pageData.totalNumberOfOrders &&
      !isBookingCreated;

    if (shouldFetchSpeculatedTransaction) {
      // const listingId = pageData.listing.id;
      // const transactionId = tx ? tx.id : null;
      const { bookingStart, bookingEnd } = pageData.bookingDates;

      // Convert picked date to date that will be converted on the API as
      // a noon of correct year-month-date combo in UTC
      const bookingStartForAPI = dateFromLocalToAPI(bookingStart);
      const bookingEndForAPI = dateFromLocalToAPI(bookingEnd);
      // Fetch speculated transaction for showing price in booking breakdown
      // NOTE: if unit type is line-item/units, quantity needs to be added.
      // The way to pass it to checkout page is through pageData.bookingData

      fetchSpeculatedTransaction(
        {
          listings,
          bookingStart: bookingStartForAPI,
          bookingEnd: bookingEndForAPI,
          promoCode: this.state.promoCode,
          providerId: provider.id.uuid,
        },
        false
      ).then(res => {
        const transaction = res.data.data;

        beginCheckoutEvent({
          currency: transaction.attributes.payinTotal.currency,
          value: transaction.attributes.payinTotal.amount / 100,
          items: listings.map(l => ({
            id: l.listing.id.uuid,
            name: l.listing.attributes.title,
            category: l.listing.attributes.businessCategory,
            provider: provider.attributes.name,
            price: l.listing.attributes.price.amount / 100,
            quantity: l.attributes.amount,
          })),
        })
      });
    }

    this.setState({ pageData: pageData || {}, dataLoaded: true });
  }

  handlePaymentIntent(handlePaymentParams) {
    const {
      currentUser,
      stripeCustomerFetched,
      onInitiateOrder,
      onUpdateProfile,
    } = this.props;

    const {
      bookingAdditionalData,
      pageData,
      speculatedTransaction,
      selectedPaymentMethod,
      saveAfterOnetimePayment,
    } = handlePaymentParams;

    const storedTx = ensureTransaction(pageData.transaction);

    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const ensuredStripeCustomer = ensureStripeCustomer(ensuredCurrentUser.stripeCustomer);
    const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(
      ensuredStripeCustomer.defaultPaymentMethod
    );

    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensuredStripeCustomer.attributes.stripeCustomerId &&
      ensuredDefaultPaymentMethod.id
    );
    const stripePaymentMethodId = hasDefaultPaymentMethod
      ? ensuredDefaultPaymentMethod.attributes.stripePaymentMethodId
      : null;

    const selectedPaymentFlow = paymentFlow(selectedPaymentMethod, saveAfterOnetimePayment);

    const { prolongFor, end } = parse(this.props.location.search);

    // Step 1: initiate order by requesting payment from Marketplace API
    const fnRequestPayment = fnParams => {
      // fnParams should be { listingId, bookingStart, bookingEnd }
      const hasPaymentIntents = storedTx.attributes && storedTx.attributes.stripePaymentIntent;
      // If paymentIntent exists, order has been initiated previously.
      return hasPaymentIntents ? Promise.resolve(storedTx) : onInitiateOrder(fnParams, storedTx.id, { prolongFor, end });
    };

    const fnSaveInvoiceDetails = fnParams => {
      const { bookingAdditionalData } = handlePaymentParams;
      if (bookingAdditionalData.saveInvoiceDetails) {
        const updatedUserParams = {
          defaultTransactionMeta: {
            ...bookingAdditionalData.invoice,
            customerType: bookingAdditionalData.customerType,
          },
        };
        return onUpdateProfile(updatedUserParams).then(res => {
          return { ...fnParams };
        });
      } else {
        return Promise.resolve({ ...fnParams });
      }
    };

    // Here we create promise calls in sequence
    // This is pretty much the same as:
    // fnRequestPayment({...initialParams})
    //   .then(result => fnHandleCardPayment({...result}))
    //   .then(result => fnConfirmPayment({...result}))
    const applyAsync = (acc, val) => acc.then(val);
    const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));
    const handlePaymentIntentCreation = composeAsync(
      fnRequestPayment,
      fnSaveInvoiceDetails
    );

    // Create order aka transaction
    // NOTE: if unit type is line-item/units, quantity needs to be added.
    // The way to pass it to checkout page is through pageData.bookingData
    const tx = speculatedTransaction ? speculatedTransaction : storedTx;
    const isFreeTx = !tx.attributes.payinTotal?.amount;

    // Note: optionalPaymentParams contains Stripe paymentMethod,
    // but that can also be passed on Step 2
    // stripe.handleCardPayment(stripe, { payment_method: stripePaymentMethodId })
    const optionalPaymentParams =
      selectedPaymentFlow === USE_SAVED_CARD && hasDefaultPaymentMethod
        ? { paymentMethod: stripePaymentMethodId }
        : selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE
          ? { setupPaymentMethodForSaving: true }
          : {};

    const orderParams = {
      listings: pageData.listings,
      bookingStart: tx.attributes.start,
      bookingEnd: tx.attributes.end,
      voucherCode: bookingAdditionalData.voucherCode,
      partnership: bookingAdditionalData.partnership,
      ...optionalPaymentParams,
      protectedData: bookingAdditionalData,
      selectedPaymentMethod,
      providerId: pageData.provider.id.uuid,
      isFreeTx
    };
    return handlePaymentIntentCreation(orderParams);
  }

  handleSubmit(values) {
    if (this.state.submitting) {
      return;
    }
    this.setState({ submitting: true });

    const { history, speculatedTransaction, currentUser, paymentIntent } = this.props;

    const { formValues } = values;
    const {
      name,
      customerFirstName,
      customerLastName,
      customerName,
      customerEmail,
      customerPhoneNumber,
      receiver,
      deliveryLocation,
      checkoutFields,
      customerType,
      delivery,
      saveInvoiceDetails,
    } = formValues;

    const billingDetails = {
      name,
      email: ensureCurrentUser(currentUser).attributes.email,
    };

    const speculatedTransactionHasPromoCode = !!speculatedTransaction.attributes.lineItems.find(
      li => li?.code === 'promo-discount'
    ) || !!speculatedTransaction.attributes.partnership;

    const promoCodeMaybe = this.computePromoCode(this.state.promoCode);

    const invoiceMaybe =
      checkoutFields && checkoutFields.invoice ? { invoice: checkoutFields.invoice } : {};
    const { invoice, ...rest } = checkoutFields || {};
    const deliveryOption =
      delivery === 'home'
        ? { deliveryToHomeAddress: rest }
        : delivery === 'store'
          ? { deliveryToProviderId: deliveryLocation }
          : {};

    const requestPaymentParams = {
      bookingAdditionalData: {
        customerFirstName,
        customerLastName,
        customerName,
        customerEmail,
        customerPhoneNumber,
        receiver,
        deliveryLocation,
        customerType,
        saveInvoiceDetails,
        ...invoiceMaybe,
        ...promoCodeMaybe,
        ...deliveryOption,
      },
      pageData: this.state.pageData,
      speculatedTransaction,
      billingDetails,
      paymentIntent
    };

    return this.handlePaymentIntent(requestPaymentParams)
      .then(res => {
        const { id } = res;
        this.setState({ submitting: false });
        const routes = routeConfiguration();
        const paymentPath = pathByRouteName('PaymentPage', routes, {
          transactionId: id.uuid,
        });

        clearData(STORAGE_KEY);

        history.push(paymentPath);
      })
      .catch(err => {
        console.error(err);
        this.setState({ submitting: false });
      });
  }

  onStripeInitialized(stripe) {
    this.stripe = stripe;

    const {
      history,
      paymentIntent,
      onRetrievePaymentIntent,
      onConfirmPayment,
      onConfirmP24Payment,
    } = this.props;
    const tx = this.state.pageData ? this.state.pageData.transaction : null;

    // We need to get up to date PI, if booking is created but payment is not expired.
    const shouldFetchPaymentIntent =
      this.stripe &&
      !paymentIntent &&
      tx &&
      tx.id &&
      tx.booking &&
      tx.booking.id &&
      txIsPaymentPending(tx) &&
      !checkIsPaymentExpired(tx);

    if (shouldFetchPaymentIntent) {
      const { stripePaymentIntentClientSecret } =
        tx.attributes.protectedData && tx.attributes.protectedData.stripePaymentIntents
          ? tx.attributes.protectedData.stripePaymentIntents.default
          : {};

      // Fetch up to date PaymentIntent from Stripe
      onRetrievePaymentIntent({ stripe, stripePaymentIntentClientSecret }).then(res => {
        if (res.paymentIntent && res.paymentIntent.status === 'succeeded') {
          const confirmFn =
            tx.attributes.lastTransition === 'transition/request-push-payment'
              ? onConfirmP24Payment
              : onConfirmPayment;
          confirmFn({
            paymentIntent: res.paymentIntent,
            transactionId: tx.id,
            transactionProtectedData: tx.attributes.protectedData,
          }).then(res => {
            const routes = routeConfiguration();
            const orderDetailsPath = pathByRouteName('OrderDetailsSuccessPage', routes, {
              id: tx.id.uuid,
            });
            history.push(orderDetailsPath);
          });
        }
      });
    }
  }

  applyPromoCode = promoCode => {
    this.setState({ promoCode }, this.speculateNewTransaction);
  };

  cancelPromoCode = () => {
    this.setState({ promoCode: '' }, this.speculateNewTransaction);
  };

  computePromoCode = promoCode => {
    if (!promoCode) return {};
    if (promoCode.toUpperCase().startsWith('MEDICOVERSPORT')) return { partnership: promoCode };
    return { voucherCode: promoCode };
  }

  speculateNewTransaction = async () => {
    const { bookingStart, bookingEnd } = this.state.pageData.bookingDates;
    const { fetchSpeculatedTransaction } = this.props;
    // Convert picked date to date that will be converted on the API as
    // a noon of correct year-month-date combo in UTC
    const bookingStartForAPI = dateFromLocalToAPI(bookingStart);
    const bookingEndForAPI = dateFromLocalToAPI(bookingEnd);

    const { prolongFor, end } = parse(this.props.location.search);

    const voucherMaybe = this.computePromoCode(this.state.promoCode);

    if (prolongFor && end) {
      return fetchSpeculatedTransaction(
        {
          prolongFor,
          end,
          ...voucherMaybe
        },
        true
      )
    }

    // Fetch speculated transaction for showing price in booking breakdown
    // NOTE: if unit type is line-item/units, quantity needs to be added.
    // The way to pass it to checkout page is through pageData.bookingData
    fetchSpeculatedTransaction(
      {
        listings: this.state.pageData.listings,
        bookingStart: bookingStartForAPI,
        bookingEnd: bookingEndForAPI,
        providerId: this.state.pageData.provider.id.uuid,
        ...voucherMaybe
      },
      true
    );
  };

  render() {
    const {
      scrollingDisabled,
      speculateTransactionInProgress,
      speculateTransactionError,
      speculatedTransaction: speculatedTransactionMaybe,
      initiateOrderError,
      confirmPaymentError,
      intl,
      currentUser,
      handleCardPaymentError,
      paymentIntent,
      stripeCustomerFetched,
      validatePromoInProgress,
      validatePromoInfo,
    } = this.props;

    if (!this.state.dataLoaded) {
      return <div>Loading...</div>;
    }

    const isLoading =
      !this.state.dataLoaded || (speculateTransactionInProgress && !this.state.validatingPromoCode);

    const {
      bookingDates,
      transaction,
      listings,
      provider,
    } = this.state.pageData;

    const redirectToCart = !bookingDates && !listings?.length && !provider;
    const { prolongFor, end: prolongationTo } = parse(this.props.location.search);

    if (redirectToCart) {
      return prolongFor && prolongationTo ? <NamedRedirect name="OrderDetailsPage" params={{ id: prolongFor }} /> : <NamedRedirect name="CartPage" />
    };

    const existingTransaction = ensureTransaction(transaction);
    const speculatedTransaction = ensureTransaction(speculatedTransactionMaybe);

    const currentAuthor = provider;

    const title = intl.formatMessage({ id: 'CheckoutPage.title' });

    const currentAuthorDisplayName = currentAuthor?.attributes.name;

    const warnings = speculatedTransaction && speculatedTransaction.attributes.warnings;
    const noTermsConfirmed = warnings
      ? warnings.find(warning => warning.error === 'No terms confirmed')
      : null;

    const pageProps = { title, scrollingDisabled, className: css.page };
    const topbar = (
      <div className={css.topbar}>
        <NamedLink className={css.home} name="LandingPage">
          <Logo
            className={css.logoMobile}
            title={intl.formatMessage({ id: 'CheckoutPage.goToLandingPage' })}
            format="mobile"
          />
          <Logo
            className={css.logoDesktop}
            alt={intl.formatMessage({ id: 'CheckoutPage.goToLandingPage' })}
            format="desktop"
          />
        </NamedLink>
      </div>
    );

    if (isLoading) {
      return <Page {...pageProps}>{topbar}</Page>;
    }

    // Show breakdown only when speculated transaction and booking are loaded
    // (i.e. have an id)
    const promoCodeApplicable =
      currentAuthor.attributes.isDecathlon && (currentAuthor.attributes.countryId === 'pl' || currentAuthor.attributes.countryId === 'cz');

    const tx =
      existingTransaction && existingTransaction.id
        ? existingTransaction
        : speculatedTransaction;

    const isPaymentExpired = checkIsPaymentExpired(existingTransaction);
    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensureStripeCustomer(currentUser.stripeCustomer).attributes.stripeCustomerId &&
      ensurePaymentMethodCard(currentUser.stripeCustomer.defaultPaymentMethod).id
    );

    const listingLink = (
      <NamedLink name="CartPage">
        <FormattedMessage id="CheckoutPage.errorlistingLinkText" />
      </NamedLink>
    );

    const isAmountTooLowError = isTransactionInitiateAmountTooLowError(initiateOrderError);
    const isChargeDisabledError = isTransactionChargeDisabledError(initiateOrderError);
    const isBookingTimeNotAvailableError = isTransactionInitiateBookingTimeNotAvailableError(
      initiateOrderError
    );
    const stripeErrors = transactionInitiateOrderStripeErrors(initiateOrderError);

    let initiateOrderErrorMessage = null;
    let listingNotFoundErrorMessage = null;

    if (isAmountTooLowError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
        </p>
      );
    } else if (isBookingTimeNotAvailableError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
        </p>
      );
    } else if (isChargeDisabledError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.chargeDisabledMessage" />
        </p>
      );
    } else if (stripeErrors && stripeErrors.length > 0) {
      // NOTE: Error messages from Stripes are not part of translations.
      // By default they are in English.
      const stripeErrorsAsString = stripeErrors.join(', ');
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage
            id="CheckoutPage.initiateOrderStripeError"
            values={{ stripeErrors: stripeErrorsAsString }}
          />
        </p>
      );
    }

    const speculateTransactionErrorMessage = (
      <p className={css.speculateError}>
        {speculateTransactionError?.message === 'No terms confirmed' ? (
          <FormattedMessage id="CheckoutPage.noTermsConfirmed" />
        ) : (
          <FormattedMessage id="CheckoutPage.speculateTransactionError" />
        )}
      </p>
    );

    let speculateErrorMessage = null;

    if (isTransactionInitiateMissingStripeAccountError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.providerStripeAccountMissingError" />
        </p>
      );
    } else if (isTransactionInitiateBookingTimeNotAvailableError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
        </p>
      );
    } else if (isTransactionZeroPaymentError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
        </p>
      );
    } else if (speculateTransactionError?.message === 'Partnership duration is too long') {
      speculateErrorMessage = (
        <p className={css.orderError}>
          Za długo
        </p>
      );
    } else if (speculateTransactionError) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          {speculateTransactionError?.message === 'No terms confirmed' ? (
            <span>
              <FormattedMessage id="CheckoutPage.noTermsConfirmed" />
              <NamedLink
                className={css.linkToProfile}
                name="ProfileSettingsPage"
              >
                <FormattedMessage id="CheckoutPage.here" />
              </NamedLink>
            </span>
          ) : (
            <FormattedMessage id="CheckoutPage.speculateFailedMessage" />
          )}
        </p>
      );
    }

    // Get first and last name of the current user and use it in the StripePaymentForm to autofill the name field
    const userName =
      currentUser && currentUser.attributes
        ? `${currentUser.attributes.firstName} ${currentUser.attributes.lastName}`
        : null;

    // If paymentIntent status is not waiting user action,
    // handleCardPayment has been called previously.
    const hasPaymentIntentUserActionsDone =
      paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);

    // If your marketplace works mostly in one country you can use initial values to select country automatically
    // e.g. {country: 'FI'}
    const { email, phoneNumber, defaultTransactionMeta } = ensureUser(currentUser).attributes;

    const defaultTransactionMetaMaybe = defaultTransactionMeta &&
      (currentAuthor.attributes.countryId === 'hu' ||
      (currentAuthor.attributes.countryId === 'pl' && currentAuthor.attributes.isDecathlon))
      ? {
        checkoutFields: {
          invoice: {
            ...defaultTransactionMeta,
          },
        },
      }
      : {};

    const initialCustomerType =
      defaultTransactionMeta && defaultTransactionMeta.customerType
        ? defaultTransactionMeta.customerType
        : 'individual';

    const initalValuesForStripePayment = {
      name: userName,
      receiver: userName,
      customerName: userName,
      customerFirstName: currentUser.attributes.firstName || null,
      customerLastName: currentUser.attributes.lastName || null,
      customerEmail: email,
      // customerPhoneNumber: phoneNumber,
      // for hungarian invoices purposes
      customerType: initialCustomerType,
      ...defaultTransactionMetaMaybe,
    };

    const { start, end, payinTotal, lineItems } = tx.attributes;

    const showDeliveryToHome = listings.some(listing => listing?.listing?.attributes.deliveryToHome);
    const showDeliveryToProviders = listings.some(
      listing => listing?.listing?.attributes.deliveryToProviders
    );
    const isStoreSelect = showDeliveryToProviders && !showDeliveryToHome;

    const showSummary = displayClass => (
      <div className={classNames(displayClass, css.summaryContainer)}>
        <OrderSummary
          start={start}
          end={end}
          payinTotal={payinTotal}
          lineItems={lineItems}
          cartListings={listings}
          intl={intl}
        />
        {promoCodeApplicable && (
          <PromoCode
            initialPromoCodeValue={this.state.promoCode}
            applyPromoCode={this.applyPromoCode}
            cancelPromoCode={this.cancelPromoCode}
            validatePromoInProgress={validatePromoInProgress}
            info={validatePromoInfo}
            intl={intl}
            transaction={tx}
          />
        )}
      </div>
    );

    const errorBookingEnd =
      'Booking end is not in an allowed range' === speculateTransactionError?.message;

    const orderListings = (
      <Order
        orders={listings}
        provider={provider}
        intl={intl}
        className={css.orderContainer}
        warnings={warnings}
      />
    );

    return (
      <Page {...pageProps}>
        {topbar}
        <div className={css.container}>
          {speculateTransactionError ? (
            errorBookingEnd ? (
              <>
                <p className={css.orderError}>
                  <FormattedMessage id="CheckoutPage.speculateTransactionError" />
                </p>
                <div className={css.wrapper}>
                  {orderListings}
                </div>
              </>
            ) : (
              speculateTransactionErrorMessage
            )
          ) : (
            <>
              {noTermsConfirmed && <WarningAcceptTerms />}
              <div className={css.wrapper}>
                <div className={css.paymentDetailsContainer}>
                  {orderListings}
                  <section className={css.paymentContainer}>
                    {initiateOrderErrorMessage}
                    {listingNotFoundErrorMessage}
                    {speculateErrorMessage}
                    {showSummary(css.mobileContainer)}

                    <StripePaymentForm
                      className={css.paymentForm}
                      onSubmit={this.handleSubmit}
                      inProgress={this.state.submitting}
                      formId="CheckoutPagePaymentForm"
                      paymentInfo={intl.formatMessage({ id: 'CheckoutPage.paymentInfo' })}
                      authorDisplayName={currentAuthorDisplayName}
                      currentProvider={currentAuthor}
                      initialValues={initalValuesForStripePayment}
                      initiateOrderError={initiateOrderError}
                      handleCardPaymentError={handleCardPaymentError}
                      confirmPaymentError={confirmPaymentError}
                      hasHandledCardPayment={hasPaymentIntentUserActionsDone}
                      loadingData={!stripeCustomerFetched}
                      defaultPaymentMethod={
                        hasDefaultPaymentMethod
                          ? currentUser.stripeCustomer.defaultPaymentMethod
                          : null
                      }
                      paymentIntent={paymentIntent}
                      onStripeInitialized={this.onStripeInitialized}
                      providerRules={currentAuthor.attributes.rentTerms}
                      listings={listings}
                      warnings={warnings}
                      isStoreSelect={isStoreSelect}
                      prolongFor={prolongFor}
                      prolongationTo={prolongationTo}
                      isPartnership={!speculatedTransaction.attributes.payinTotal?.amount}
                    />
                    {isPaymentExpired ? (
                      <p className={css.orderError}>
                        <FormattedMessage
                          id="CheckoutPage.paymentExpiredMessage"
                          values={{ listingLink }}
                        />
                      </p>
                    ) : null}
                  </section>
                </div>
                {showSummary(css.desktopContainer)}
              </div>
            </>
          )}
        </div>
      </Page>
    );
  }
}

CheckoutPageComponent.defaultProps = {
  initiateOrderError: null,
  confirmPaymentError: null,
  listing: null,
  bookingData: {},
  bookingDates: null,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  currentUser: null,
  paymentIntent: null,
};

const mapStateToProps = state => {
  const {
    listing,
    listings,
    provider,
    bookingData,
    bookingDates,
    stripeCustomerFetched,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    transaction,
    initiateOrderError,
    confirmPaymentError,
    validatePromoInProgress,
    validatePromoInfo,
    validatePromoError,
    totalNumberOfOrders,
  } = state.CheckoutPage;
  const { currentUser } = state.user;
  const { handleCardPaymentError, paymentIntent, retrievePaymentIntentError } = state.stripe;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    stripeCustomerFetched,
    bookingData,
    bookingDates,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    transaction,
    listing,
    listings,
    provider,
    initiateOrderError,
    handleCardPaymentError,
    confirmPaymentError,
    paymentIntent,
    retrievePaymentIntentError,
    validatePromoInProgress,
    validatePromoInfo,
    validatePromoError,
    totalNumberOfOrders,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  fetchSpeculatedTransaction: (params, validatingPromoCode) =>
    dispatch(speculateTransaction(params, validatingPromoCode)),
  fetchStripeCustomer: () => dispatch(stripeCustomer()),
  onInitiateOrder: (params, transactionId, prolongationObj) => dispatch(initiateOrder(params, transactionId, prolongationObj)),
  onRetrievePaymentIntent: params => dispatch(retrievePaymentIntent(params)),
  onHandleCardPayment: params => dispatch(handleCardPayment(params)),
  onHandleP24Payment: params => dispatch(handleP24Payment(params)),
  onConfirmPayment: params => dispatch(confirmPayment(params)),
  onConfirmP24Payment: params => dispatch(confirmP24Payment(params)),
  onSavePaymentMethod: (stripeCustomer, stripePaymentMethodId) =>
    dispatch(savePaymentMethod(stripeCustomer, stripePaymentMethodId)),
  onUpdateProfile: params => dispatch(updateProfile(params)),
});

const CheckoutPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(CheckoutPageComponent);

CheckoutPage.setInitialValues = initialValues => setInitialValues(initialValues);

CheckoutPage.displayName = 'CheckoutPage';

export default CheckoutPage;
