/**
 * This is the main entrypoint file for the application.
 *
 * When loaded in the client side, the application is rendered in the
 * #root element.
 *
 * When the bundle created from this file is imported in the server
 * side, the exported `renderApp` function can be used for server side
 * rendering.
 *
 * Note that this file is required for the build process.
 */

// React 16 depends on the collection types Map and Set, as well as requestAnimationFrame.
// https://reactjs.org/docs/javascript-environment-requirements.html
import 'core-js/features/map';
import 'core-js/features/set';
import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { types as sdkTypes } from './util/sdkLoader';
import { ClientApp, renderApp } from './app';
import configureStore from './store';
import { matchPathname } from './util/routes';
import * as sample from './util/sample';
import config from './config';
import { authInfo } from './ducks/Auth.duck';
import { fetchCurrentUser } from './ducks/user.duck';
import routeConfiguration from './routeConfiguration';
import { initMissingAbExperiments } from './abTest';
import * as log from './util/log';
import { LoggingAnalyticsHandler, GoogleAnalyticsHandler, GoogleAnalytics4Handler } from './analytics/handlers';
import { createRentSDK } from '@dktunited/decathlon-rent-sdk';
import axios from 'axios';

import './marketplaceIndex.css';

const render = (store, shouldHydrate) => {
  // If the server already loaded the auth information, render the app
  // immediately. Otherwise wait for the flag to be loaded and render
  // when auth information is present.
  const authInfoLoaded = store.getState().Auth.authInfoLoaded;
  const info = authInfoLoaded ? Promise.resolve({}) : store.dispatch(authInfo());
  info
    .then(() => {
      store.dispatch(fetchCurrentUser());
      if (shouldHydrate) {
        ReactDOM.hydrate(<ClientApp store={store} />, document.getElementById('root'));
      } else {
        ReactDOM.render(<ClientApp store={store} />, document.getElementById('root'));
      }
    })
    .catch(e => {
      log.error(e, 'browser-side-render-failed');
    });
};

const setupAnalyticsHandlers = () => {
  let handlers = [];

  // Log analytics page views and events in dev mode
  if (config.dev) {
    handlers.push(new LoggingAnalyticsHandler());
  }

  // Add Google Analytics handler if tracker ID is found
  if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
    handlers.push(new GoogleAnalyticsHandler(window.ga));
  }

  handlers.push(new GoogleAnalytics4Handler());

  return handlers;
};

// If we're in a browser already, render the client application.
if (typeof window !== 'undefined') {
  // set up logger with Sentry DSN client key and environment
  log.setup();

  const baseUrl = config.sdk.baseUrl ? { baseUrl: config.sdk.baseUrl } : {};

  // eslint-disable-next-line no-underscore-dangle
  const preloadedState = window.__PRELOADED_STATE__ || '{}';
  const initialState = JSON.parse(preloadedState, sdkTypes.reviver);

  // add localStorage to initialState
  // const promoKeyMaybe = localStorage.getItem('Promo') === null ? {} : {Promo: JSON.parse(localStorage.getItem('Promo'))};
  const promoKeyMaybe = {};
  const initialStateWithStorageData = {
    ...initialState,
    ...promoKeyMaybe
  }

  // const sdk = createInstance({
  //   transitVerbose: config.sdk.transitVerbose,
  //   clientId: config.sdk.clientId,
  //   secure: config.usingSSL,
  //   typeHandlers: apiUtils.typeHandlers,
  //   ...baseUrl,
  // });

  const sdk = {};

  const gaStreamIds = {
    'https://rent.decathlon.pl': 'FPQQDDNDNR',
    'https://rent.decathlon.hu': '1KLKGN4BQK',
    'https://rent.decathlon.cz': '0M748LRDS1',
    'https://rent.decathlon.ee': 'N1L4CLBR44',
    'https://rent.decathlon.lt': 'X4E059KNLW',
    'https://rent.decathlon.lv': 'KNTCPFYMYG',
  }

  const newSdk = createRentSDK(process.env.REACT_APP_RENT_API_URL, {
    useUnsafeRefreshToken: process.env.REACT_APP_LOCALTOKEN === 'true',
    axios,
    countryId: process.env.REACT_APP_COUNTRY_ID,
    gaStreamId: gaStreamIds[process.env.REACT_APP_CANONICAL_ROOT_URL] || undefined,
  });

  sdk.newSdk = newSdk;

  const analyticsHandlers = setupAnalyticsHandlers();
  const store = configureStore(initialStateWithStorageData, sdk, analyticsHandlers);

  require('./util/polyfills');
  render(store, !!window.__PRELOADED_STATE__);

  window.kds = newSdk;

  if (config.dev) {
    // Expose stuff for the browser REPL
    window.app = {
      config,
      sdk,
      sdkTypes,
      store,
      sample,
      routeConfiguration: routeConfiguration(),
    };
  }
}

// Export the function for server side rendering.
export default renderApp;

// exporting matchPathname and configureStore for server side rendering.
// matchPathname helps to figure out which route is called and if it has preloading needs
// configureStore is used for creating initial store state for Redux after preloading
export { matchPathname, configureStore, routeConfiguration, initMissingAbExperiments, config };
