import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { propTypes } from '../../util/types';
import { ListingCard, PaginationLinks } from '../../components';

import css from './SearchResultsPanel.css';
import { FormattedMessage } from 'react-intl';

const SearchResultsPanel = props => {
  const { className, rootClassName, listings, pagination, search, setActiveListing, pageName } = props;
  const classes = classNames(rootClassName || css.root, className);

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName={pageName || "SearchPage"}
        pageSearchParams={search}
        pagination={pagination}
      />
    ) : null;

  // Panel width relative to the viewport
  const panelMediumWidth = 70;
  const panelLargeWidth = 70;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  const domainListings = listings.filter(l => l.provider.attributes.countryId === process.env.REACT_APP_COUNTRY_ID);
  const otherListings = listings.filter(l => l.provider.attributes.countryId !== process.env.REACT_APP_COUNTRY_ID);

  return (
    <div className={classes}>
      <div className={css.listingCards}>
        {domainListings.map(l => (
          <ListingCard
            className={css.listingCard}
            key={l.id.uuid}
            listing={l}
            renderSizes={cardRenderSizes}
            setActiveListing={setActiveListing}
            locationCenter={search.origin}
            isUserLocation={search.isLocation}
          />
        ))}
        {props.children}
      </div>
      <div>
        {
          otherListings?.length > 0 &&
          <div className={css.otherListings}><FormattedMessage id="SearchResultsPanel.otherListings" /></div>
        }
        <div className={css.listingCards}>
          {otherListings.map(l => (
            <ListingCard
              className={css.listingCard}
              key={l.id.uuid}
              listing={l}
              renderSizes={cardRenderSizes}
              setActiveListing={setActiveListing}
              locationCenter={search.origin}
              isUserLocation={search.isLocation}
            />
          ))}
        </div>
      </div>
      {paginationLinks}
    </div>
  );
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
};

const { array, node, object, string } = PropTypes;

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
};

export default SearchResultsPanel;
