import React from 'react';
import { array, bool, func, object, string } from 'prop-types';
import classNames from 'classnames';

import config from '../../../config';
import { FormattedMessage } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { SearchResultsPanel } from '../../../components';
import { NoResultsForm } from '../../../forms';
import { validFilterParams } from '../SearchPage.helpers';
import FilterContainer from '../FilterContainer/FilterContainer';

import css from './MainPanel.css';

const MainPanel = ({
  className,
  rootClassName,
  urlQueryParams,
  listings,
  searchInProgress,
  searchListingsError,
  searchParamsAreInSync,
  onActivateListing,
  onManageDisableScrolling,
  onMapIconClick,
  pagination,
  providers,
  searchParamsForPagination,
  filterConfig,
  suggestions,
  categoryConfig,
  pageName
}) => {
  // Selected aka active filters
  const selectedFilters = validFilterParams(urlQueryParams, filterConfig);

  const hasPaginationInfo =
    !!pagination && pagination.totalItems != null && !pagination.paginationUnsupported;
  const listingsLength = listings ? listings.length : 0;
  const totalItems =
    searchParamsAreInSync && hasPaginationInfo ? pagination.totalItems : listingsLength;
  const listingsAreLoaded =
    !searchInProgress &&
    searchParamsAreInSync &&
    (hasPaginationInfo || selectedFilters.availability === 'day-partial');

  const hasNoResult = listingsAreLoaded && totalItems === 0;
  const classes = classNames(rootClassName || css.searchResultContainer, className);

  const sortedProviders = providers.sort((a, b) =>
    b.attributes?.name.toLowerCase() < a.attributes?.name.toLowerCase() ? 1 : -1
  );

  return (
    <div className={classes}>
      {
        categoryConfig?.header && 
        <div className={css.pageHeader}>
          <h1>{categoryConfig.header}</h1>
        </div>
      }
      <FilterContainer
        listingsAreLoaded={listingsAreLoaded}
        onManageDisableScrolling={onManageDisableScrolling}
        onMapIconClick={onMapIconClick}
        providers={sortedProviders}
        totalItems={totalItems}
        suggestions={suggestions}
        pageName={pageName}
      />
      <div
        className={classNames(css.listings, {
          [css.newSearchInProgress]: !listingsAreLoaded,
        })}
      >
        {searchListingsError ? (
          <h2 className={css.error}>
            <FormattedMessage id="SearchPage.searchError" />
          </h2>
        ) : null}
        {hasNoResult ? <div className={css.wrapper}><NoResultsForm /></div> : null}
        {searchInProgress ? (
          <div className={css.loadingResults}>
            <FormattedMessage id="SearchFiltersPrimary.loadingResults" />
          </div>
        ) : null}
        <SearchResultsPanel
          className={css.searchListingsPanel}
          listings={listings}
          pagination={listingsAreLoaded ? pagination : null}
          search={searchParamsForPagination}
          setActiveListing={onActivateListing}
          pageName={pageName}
        />
        {
          categoryConfig?.description && pagination?.page === 1 &&
          <div
            className={css.content}
            dangerouslySetInnerHTML={{ __html: categoryConfig.description }}
          />
        }
      </div>
    </div>
  );
};

MainPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listings: [],
  resultsCount: 0,
  pagination: null,
  searchParamsForPagination: {},
  filterConfig: config.custom.filters,
  pageName: 'SearchPage'
};

MainPanel.propTypes = {
  className: string,
  rootClassName: string,
  urlQueryParams: object.isRequired,
  listings: array,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParamsAreInSync: bool.isRequired,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onMapIconClick: func.isRequired,
  pagination: propTypes.pagination,
  searchParamsForPagination: object,
  filterConfig: propTypes.filterConfig,
  pageName: string,
};

export default MainPanel;
