import React, { useState, useEffect } from 'react';
import { array, bool, func, oneOf, object, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import unionWith from 'lodash/unionWith';
import classNames from 'classnames';

import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { parse, stringify, decodeLatLngBounds, encodeLatLngBounds, decodeLatLng } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { fetchCategoriesSuggestions } from '../../ducks/categoriesSuggestions.duck';

import {
  searchListings,
  searchMapListings,
  setActiveListing,
  fetchProvidersForMap
} from './SearchPage.duck';
import { SearchMap, ModalInMobile, Page } from '../../components';
import { TopbarContainer } from '../../containers';
import MainPanel from './MainPanel/MainPanel';
import {
  pickSearchParamsOnly,
  validURLParamsForExtendedData,
  validFilterParams,
  createSearchResultSchema,
} from './SearchPage.helpers';


import css from './SearchPage.css';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 48;
// const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const MODAL_BREAKPOINT = 10000;
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.
const NUMBER_OF_DEGREES_FROM_ORIGIN = 0.03;

const SearchPageComponent = ({
  activeListingId,
  fetchProvidersForMap,
  fetchSuggestions,
  filterConfig,
  history,
  intl,
  listings,
  location,
  mapListings,
  onActivateListing,
  onManageDisableScrolling,
  sortConfig,
  pagination,
  providers,
  scrollingDisabled,
  searchInProgress,
  searchListingsError,
  searchParams,
  categoriesSuggestions,
  tab,
  categoryConfig,
  pageName
}) => {
  const originParam = parse(location.search).origin && decodeLatLng(parse(location.search).origin);
  const boundsCity = originParam
    ? `${originParam.lat + NUMBER_OF_DEGREES_FROM_ORIGIN},${originParam.lng +
        NUMBER_OF_DEGREES_FROM_ORIGIN},${originParam.lat -
        NUMBER_OF_DEGREES_FROM_ORIGIN},${originParam.lng - NUMBER_OF_DEGREES_FROM_ORIGIN}`
    : undefined;
  const isLocationStateBounds = location.state?.bounds && !!Object.keys(location?.state.bounds).length
  const defaultBounds = isLocationStateBounds ? location.state.bounds : decodeLatLngBounds(boundsCity || config.maps.search.countryBounds.bounds);
  const [isSearchMapOpenOnMobile, setIsSearchMapOpenOnMobile] = useState(tab === 'map');
  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);
  const [boundsMap, setBoundsMap] = useState(defaultBounds);

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  const onOpenMobileModal = () => {
    setIsMobileModalOpen(true);
  };

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  const onCloseMobileModal = () => {
    setIsMobileModalOpen(false);
  };

  const updateBounds = ( boundsValue ) => {
    setBoundsMap(boundsValue);
  };

  // eslint-disable-next-line no-unused-vars
  const { mapSearch, page, ...searchInURL } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  // urlQueryParams doesn't contain page specific url params
  // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
  const urlQueryParams = pickSearchParamsOnly(searchInURL, filterConfig, sortConfig);

  // Page transition might initially use values from previous search
  const urlQueryString = stringify(urlQueryParams);
  const paramsQueryString = stringify(pickSearchParamsOnly(searchParams, filterConfig, sortConfig));
  const searchParamsAreInSync = urlQueryString === paramsQueryString;

  const validQueryParams = validURLParamsForExtendedData(searchInURL, filterConfig);

  const isWindowDefined = typeof window !== 'undefined';
  const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;
  const shouldShowSearchMap = !isMobileLayout || (isMobileLayout && isSearchMapOpenOnMobile);

  const onMapIconClick = () => {
    setIsSearchMapOpenOnMobile(true);
  };

  const { address, bounds, origin } = searchInURL || {};
  const { title, description, schema } = createSearchResultSchema(listings, address, intl);

  // Set topbar class based on if a modal is open in
  // a child component
  const topbarClasses = isMobileModalOpen
    ? classNames(css.topbarBehindModal, css.topbar)
    : css.topbar;
  // N.B. openMobileMap button is sticky.
  // For some reason, stickyness doesn't work on Safari, if the element is <button>
  /* eslint-disable jsx-a11y/no-static-element-interactions */

  useEffect(() => {
     setBoundsMap(defaultBounds);
  },[location.state?.bounds]);

  useEffect(() => {
    fetchProvidersForMap();
    fetchSuggestions();
  }, []);

  return (
    <Page
      scrollingDisabled={scrollingDisabled}
      description={categoryConfig?.metaDescription || description}
      title={categoryConfig?.metaTitle || title}
      schema={schema}
    >
      <TopbarContainer
        className={topbarClasses}
        currentPage={pageName}
        currentSearchParams={urlQueryParams}
      />
      <div className={css.container}>
        <MainPanel
          urlQueryParams={validQueryParams}
          listings={listings}
          searchInProgress={searchInProgress}
          searchListingsError={searchListingsError}
          searchParamsAreInSync={searchParamsAreInSync}
          onActivateListing={onActivateListing}
          onManageDisableScrolling={onManageDisableScrolling}
          onOpenModal={onOpenMobileModal}
          onCloseModal={onCloseMobileModal}
          onMapIconClick={onMapIconClick}
          pagination={pagination}
          searchParamsForPagination={parse(location.search)}
          showAsModalMaxWidth={MODAL_BREAKPOINT}
          providers={providers}
          currentSearchParams={urlQueryParams}
          bounds={boundsMap}
          suggestions={categoriesSuggestions}
          categoryConfig={categoryConfig}
          pageName={pageName}
        />
        <ModalInMobile
          className={css.mapPanel}
          closeButtonClassName={css.closeButtonClassName}
          closeIconClassName={css.closeIconClassName}
          id="SearchPage.map"
          isModalOpenOnMobile={isSearchMapOpenOnMobile}
          isShowText={false}
          onClose={() => setIsSearchMapOpenOnMobile(false)}
          showAsModalMaxWidth={MODAL_BREAKPOINT}
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={shouldShowSearchMap ? css.modalContainer : css.modalClose}
        >
          <div className={css.mapWrapper}>
            {shouldShowSearchMap ? (
              <SearchMap
                reusableContainerClassName={css.map}
                activeListingId={activeListingId}
                bounds={boundsMap}
                center={origin}
                isSearchMapOpenOnMobile={isSearchMapOpenOnMobile}
                location={location}
                listings={mapListings || []}
                onCloseAsModal={() => {
                  onManageDisableScrolling('SearchPage.map', false);
                }}
                onCloseMap={() => setIsSearchMapOpenOnMobile(false)}
                pagination={pagination}
                messages={intl.messages}
                providers={providers}
                history={history}
                updateBounds={updateBounds}
              />
            ) : null}
          </div>
        </ModalInMobile>
      </div>
      <ScrollToTop />
    </Page>
  );
  /* eslint-enable jsx-a11y/no-static-element-interactions */
};

SearchPageComponent.defaultProps = {
  listings: [],
  mapListings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
  activeListingId: null,
};

SearchPageComponent.propTypes = {
  listings: array,
  mapListings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onSearchMapListings: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,
  filterConfig: propTypes.filterConfig,
  sortConfig: propTypes.sortConfig,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    searchMapListingIds,
    activeListingId,
    providers,
  } = state.SearchPage;
  const { categoriesSuggestions } = state.categoriesSuggestions;
  const pageListings = getListingsById(state, currentPageResultIds);
  const mapListings = getListingsById(
    state,
    unionWith(currentPageResultIds, searchMapListingIds, (id1, id2) => id1.uuid === id2.uuid)
  );

  return {
    listings: pageListings,
    mapListings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
    providers,
    categoriesSuggestions
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSearchMapListings: searchParams => dispatch(searchMapListings(searchParams)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
  fetchProvidersForMap: countryIdParam => dispatch(fetchProvidersForMap(countryIdParam)),
  fetchSuggestions: () => dispatch( fetchCategoriesSuggestions()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SearchPageComponent);

SearchPage.loadData = (params, search, businessCategory) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const { page = 1, ...rest } = queryParams;

  const businessCategoryMaybe = businessCategory ? { businessCategory } : {};

  return searchListings({
    ...rest,
    ...businessCategoryMaybe,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['provider', 'images'],
    'fields.listing': ['title', 'price','businessCategory'],
  });
};

export default SearchPage;
