/**
 * This is a wrapper component for different Layouts.
 * Navigational 'aside' content should be added to this wrapper.
 */
import React, { useEffect } from 'react';
import { node, number, string, shape } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from '../../util/reactIntl';
import { withViewport } from '../../util/contextHelpers';
import { LayoutWrapperSideNav } from '../../components';
import { ensureCurrentUser } from '../../util/data';
import { createGlobalState } from './hookGlobalState';


const MAX_HORIZONTAL_NAV_SCREEN_WIDTH = 1023;
const initialScrollState = { scrollLeft: 0 };
const { useGlobalState } = createGlobalState(initialScrollState);

const scrollToTab = (currentTab, scrollLeft, setScrollLeft) => {
  const el = document.querySelector(`#${currentTab}Tab`);
  if (el) {
    // el.scrollIntoView doesn't work with Safari and it considers vertical positioning too.
    // This scroll behaviour affects horizontal scrolling only
    // and it expects that the immediate parent element is scrollable.
    const parent = el.parentElement;
    const parentRect = parent.getBoundingClientRect();
    const maxScrollDistance = parent.scrollWidth - parentRect.width;

    const hasParentScrolled = parent.scrollLeft > 0;
    const scrollPositionCurrent = hasParentScrolled ? parent.scrollLeft : scrollLeft;

    const tabRect = el.getBoundingClientRect();
    const diffLeftBetweenTabAndParent = tabRect.left - parentRect.left;
    const tabScrollPosition = parent.scrollLeft + diffLeftBetweenTabAndParent;

    const scrollPositionNew =
      tabScrollPosition > maxScrollDistance
        ? maxScrollDistance
        : parent.scrollLeft + diffLeftBetweenTabAndParent;

    const needsSmoothScroll = scrollPositionCurrent !== scrollPositionNew;

    if (!hasParentScrolled || (hasParentScrolled && needsSmoothScroll)) {
      parent.scrollTo({ left: scrollPositionCurrent });
      parent.scrollTo({ left: scrollPositionNew, behavior: 'smooth' });
    }
    setScrollLeft(scrollPositionNew);
  }
};

const LayoutWrapperAccountSettingsSideNavComponent = props => {
  const { currentTab, currentUser, viewport, currentUserProviders } = props;
  const [scrollLeft, setScrollLeft] = useGlobalState('scrollLeft');
  useEffect(() => {
    const { currentTab, viewport } = props;
    let scrollTimeout = null;

    const { width } = viewport;
    const hasViewport = width > 0;
    const hasHorizontalTabLayout = hasViewport && width <= MAX_HORIZONTAL_NAV_SCREEN_WIDTH;

    if (hasHorizontalTabLayout) {
      scrollTimeout = window.setTimeout(() => {
        scrollToTab(currentTab, scrollLeft, setScrollLeft);
      }, 300);
    }

    return () => {
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
    };
  });

  const user = ensureCurrentUser(currentUser);

  const isOfferEnabled = !!(user.attributes.hasProvider || user.attributes.isAdmin);

  const providersWithOwnerRole = currentUserProviders.filter(provider => provider.attributes.myRole === 'owner');
  const sortedProviders = providersWithOwnerRole?.sort((a, b) =>
    b.attributes?.name.toLowerCase() < a.attributes?.name.toLowerCase() ? 1 : -1
  );

  const paymentsTab = isOfferEnabled ? [{
    text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.paymentsTabTitle" />,
    // icon: <IconCompany />,
    selected: currentTab === 'StripePayoutPage',
    id: 'StripePayoutPageTab',
    linkProps: {
      name: 'StripePayoutSettingsPage',
    }}] : []

  const dktResultsTab = (currentUserProviders && !!currentUserProviders.find(p => p.attributes.isDecathlon) || user.attributes.isAdmin) ? [{
    text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.results" />,
    // icon: <IconCompany />,
    selected: currentTab === 'BusinessDktPage',
    id: 'BusinessDktPageTab',
    linkProps: {
      name: 'BusinessDktPage',
    },
  }] : [];

  const resultsTab = (currentUserProviders && !!currentUserProviders.length) ? [{
    text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.yourResults" />,
    // icon: <IconCompany />,
    selected: currentTab === 'BusinessResultsPage',
    id: 'BusinessResultsPageTab',
    linkProps: {
      name: 'BusinessResultsPage',
      to: {
        search: `?providerId=${currentUserProviders[0].id.uuid}`
      }
    },
  }] : [];

  const teamTab = !!providersWithOwnerRole.length ? [
    {
    text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.team" />,
    selected: currentTab === 'TeamPage',
    id: 'TeamPageTab',
    linkProps: {
      name: 'TeamPage',
      params: { providerId: sortedProviders[0].id.uuid }
    },
  }
  ] : [];

  const providerTab = !!providersWithOwnerRole.length ? [
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.provider" />,
      selected: currentTab === 'ProviderPage',
      id: 'ProviderPageTab',
      linkProps: {
        name: 'ProviderPage',
        params: { providerId: sortedProviders[0].id.uuid }
      },
    }
  ] : [];

  const tabs = [
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.offer" />,
      selected: currentTab === 'ManageListingsPage',
      id: 'ManageListingsPageTab',
      linkProps: {
        name: 'ManageListingsPage',
        params: { providerId: (currentUserProviders && !!currentUserProviders.length && currentUserProviders[0].id.uuid) || 'orders' }
      },
    },
    ...paymentsTab,
    ...dktResultsTab,
    ...resultsTab,
    ...teamTab,
    ...providerTab
  ];

  return <LayoutWrapperSideNav tabs={tabs} />;
};

LayoutWrapperAccountSettingsSideNavComponent.defaultProps = {
  className: null,
  rootClassName: null,
  children: null,
  currentTab: null,
};

LayoutWrapperAccountSettingsSideNavComponent.propTypes = {
  children: node,
  className: string,
  rootClassName: string,
  currentTab: string,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const LayoutWrapperAccountSettingsSideNav = compose(
  connect((state) => {
    const { userProviders: currentUserProviders } = state.user;
    return {
      currentUser: state.user.currentUser,
      currentUserProviders
    }
  }),
  withViewport
)(
  LayoutWrapperAccountSettingsSideNavComponent
);

export default LayoutWrapperAccountSettingsSideNav;
