import React from 'react';
import { createIntl, createIntlCache } from 'react-intl';
import { SearchPage } from './containers';

const cache = createIntlCache();

const messagesInLocale = require(`./translations/${process.env.REACT_APP_LANG || 'pl'}.json`)

const intl = createIntl(
  {
    locale: process.env.REACT_APP_LANG,
    messages: messagesInLocale,
  },
  cache
)

const categoryRoutesConfiguration = () => {
  return [
    {
      countries: [],
      key: 'wintersports_alpineskiing',
      path: intl.formatMessage({ id: 'urls.alpineskiingRent' }),
      name: 'AlpineSkiingSearchPage',
      component: props => <SearchPage
        categoryConfig={{
          header: intl.formatMessage({ id: 'SearchPage.alpineskiingHeader' }),
          description: intl.formatMessage({ id: 'SearchPage.alpineskiingDescription' }),
          metaTitle: intl.formatMessage({ id: 'SearchPage.alpineskiingMetaTitle' }),
          metaDescription: intl.formatMessage({ id: 'SearchPage.alpineskiingMetaDescription' }),
        }}
        pageName="AlpineSkiingSearchPage"
        {...props}
      />,
      loadData: (params, search) => SearchPage.loadData(params, search, 'wintersports_alpineskiing'),
    },
    {
      countries: [],
      key: 'bikes_gravel',
      path: intl.formatMessage({ id: 'urls.bikesGravelRent' }),
      name: 'GravelBikesSearchPage',
      component: props => <SearchPage
        categoryConfig={{
          header: intl.formatMessage({ id: 'SearchPage.bikesGravelHeader' }),
          description: intl.formatMessage({ id: 'SearchPage.bikesGravelDescription' }),
          metaTitle: intl.formatMessage({ id: 'SearchPage.bikesGravelMetaTitle' }),
          metaDescription: intl.formatMessage({ id: 'SearchPage.bikesGravelMetaDescription' }),
        }}
        pageName="GravelBikesSearchPage"
        {...props}
      />,
      loadData: (params, search) => SearchPage.loadData(params, search, 'bikes_gravel'),
    },
    {
      countries: ['pl'],
      key: 'bikes_road',
      path: intl.formatMessage({ id: 'urls.bikesRoadRent' }),
      name: 'RoadBikesSearchPage',
      component: props => <SearchPage
        categoryConfig={{
          header: intl.formatMessage({ id: 'SearchPage.bikesRoadHeader' }),
          description: intl.formatMessage({ id: 'SearchPage.bikesRoadDescription' }),
          metaTitle: intl.formatMessage({ id: 'SearchPage.bikesRoadMetaTitle' }),
          metaDescription: intl.formatMessage({ id: 'SearchPage.bikesRoadMetaDescription' }),
        }}
        pageName="RoadBikesSearchPage"
        {...props}
      />,
      loadData: (params, search) => SearchPage.loadData(params, search, 'bikes_road'),
    },
    {
      countries: ['pl'],
      key: 'bikes_electric',
      path: intl.formatMessage({ id: 'urls.bikesElectricRent' }),
      name: 'ElectricBikesSearchPage',
      component: props => <SearchPage
        categoryConfig={{
          header: intl.formatMessage({ id: 'SearchPage.bikesElectricHeader' }),
          description: intl.formatMessage({ id: 'SearchPage.bikesElectricDescription' }),
          metaTitle: intl.formatMessage({ id: 'SearchPage.bikesElectricMetaTitle' }),
          metaDescription: intl.formatMessage({ id: 'SearchPage.bikesElectricMetaDescription' }),
        }}
        pageName="ElectricBikesSearchPage"
        {...props}
      />,
      loadData: (params, search) => SearchPage.loadData(params, search, 'bikes_electric'),
    },
    {
      countries: ['pl'],
      key: 'bikes_trailer',
      path: intl.formatMessage({ id: 'urls.bikesTrailerRent' }),
      name: 'BikesTrailerSearchPage',
      component: props => <SearchPage
        categoryConfig={{
          header: intl.formatMessage({ id: 'SearchPage.bikesTrailerHeader' }),
          description: intl.formatMessage({ id: 'SearchPage.bikesTrailerDescription' }),
          metaTitle: intl.formatMessage({ id: 'SearchPage.bikesTrailerMetaTitle' }),
          metaDescription: intl.formatMessage({ id: 'SearchPage.bikesTrailerMetaDescription' }),
        }}
        pageName="BikesTrailerSearchPage"
        {...props}
      />,
      loadData: (params, search) => SearchPage.loadData(params, search, 'bikes_trailer'),
    }
  ];
};

export default categoryRoutesConfiguration;
