import React, { Component } from 'react';
import { func, object, shape, string } from 'prop-types';
import {
  DayPickerSingleDateController,
} from 'react-dates';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import moment from 'moment';
import {
  ensureDayAvailabilityPlan,
} from '../../util/data';
import { DAYS_OF_WEEK, propTypes } from '../../util/types';
import { monthIdString, monthIdStringInUTC } from '../../util/dates';
import { IconArrowHead, IconSpinner, Modal } from '../../components';
import EditSeats from './EditSeats';
import {
  nextMonthFn, momentToUTCDate, findException, makeDraftException, dateStartAndEndInUTC, isAfterEndOfBookingRange,
  isMonthInRange, isPast, isAfterEndOfRange, dateModifiers, dayWidth, renderDayContents, prevMonthFn, isOutsideRange
} from './manageAvailability.helpers';

import css from './ManageAvailabilityCalendar.css';

// Constants

const HORIZONTAL_ORIENTATION = 'horizontal';
const MAX_AVAILABILITY_EXCEPTIONS_RANGE = 365;
const MAX_BOOKINGS_RANGE = 180;
const TODAY_MOMENT = moment().startOf('day');
const END_OF_RANGE_MOMENT = TODAY_MOMENT.clone()
  .add(MAX_AVAILABILITY_EXCEPTIONS_RANGE - 1, 'days')
  .startOf('day');
const END_OF_BOOKING_RANGE_MOMENT = TODAY_MOMENT.clone()
  .add(MAX_BOOKINGS_RANGE - 1, 'days')
  .startOf('day');

// Constants for calculating day width (aka table cell dimensions)
const TABLE_BORDER = 2;
const TABLE_COLUMNS = 7;

////////////////////////////////
// ManageAvailabilityCalendar //
////////////////////////////////
class ManageAvailabilityCalendar extends Component {
  constructor(props) {
    super(props);

    // DOM refs
    this.dayPickerWrapper = null;
    this.dayPicker = null;

    this.state = {
      currentMonth: moment().startOf('month'),
      focused: true,
      date: null,
      isSeatsModalOpen: false
    };

    // this.fetchMonthData = this.fetchMonthData.bind(this);
    this.onDayAvailabilityChange = this.onDayAvailabilityChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.onMonthClick = this.onMonthClick.bind(this);
  }

  // componentDidMount() {
  //   // Fetch month data if user have navigated to availability tab in EditListingWizard
  //   this.fetchMonthData(this.state.currentMonth);
  //   // Fetch next month too.
  //   this.fetchMonthData(nextMonthFn(this.state.currentMonth));
  // }

  // fetchMonthData(monthMoment) {
  //   const { availability, listingId } = this.props;

  //   // Don't fetch exceptions for past months or too far in the future
  //   if (isMonthInRange(monthMoment, TODAY_MOMENT, END_OF_RANGE_MOMENT)) {
  //     // Use "today", if the first day of given month is in the past
  //     const startMoment = isPast(monthMoment) ? TODAY_MOMENT : monthMoment;
  //     const start = momentToUTCDate(startMoment);

  //     // Use END_OF_RANGE_MOMENT, if the first day of the next month is too far in the future
  //     const nextMonthMoment = nextMonthFn(monthMoment);
  //     const endMoment = isAfterEndOfRange(nextMonthMoment)
  //       ? END_OF_RANGE_MOMENT.clone().add(1, 'days')
  //       : nextMonthMoment;
  //     const end = momentToUTCDate(endMoment);

  //     // Fetch AvailabilityExceptions for this month
  //     console.log(availability)
  //     // availability.onFetchAvailabilityExceptions({ listingId, start, end });

  //     // Fetch Bookings if the month is within bookable range (180 days)
  //     if (isMonthInRange(startMoment, TODAY_MOMENT, END_OF_BOOKING_RANGE_MOMENT)) {
  //       const endMomentForBookings = isAfterEndOfBookingRange(nextMonthMoment)
  //         ? END_OF_BOOKING_RANGE_MOMENT.clone().add(1, 'days')
  //         : nextMonthMoment;
  //       const endForBookings = momentToUTCDate(endMomentForBookings);

  //       // Fetch Bookings for this month (if they are in pending or accepted state)
  //       const state = ['pending', 'accepted'].join(',');
  //       // availability.onFetchBookings({ listingId, start, end: endForBookings, state });
  //     }
  //   }
  // }

  onDayAvailabilityChange(date, seats, exceptions) {
    const { availabilityPlan, listingId } = this.props;
    const { start, end } = dateStartAndEndInUTC(date);

    const planEntries = ensureDayAvailabilityPlan(availabilityPlan).entries;
    const seatsFromPlan = planEntries.find(
      weekDayEntry => weekDayEntry.dayOfWeek === DAYS_OF_WEEK[date.isoWeekday() - 1]
    ).seats;

    const currentException = findException(exceptions, date);
    const draftException = makeDraftException(exceptions, start, end, seatsFromPlan);
    const exception = currentException || draftException;
    const hasAvailabilityException = currentException && currentException.availabilityException.id;

    if (hasAvailabilityException) {
      const id = currentException.availabilityException.id;
      const isResetToPlanSeats = seatsFromPlan === seats;

      if (isResetToPlanSeats) {
        // Delete the exception, if the exception is redundant
        // (it has the same content as what user has in the plan).
        this.props.availability.onDeleteAvailabilityException({
          id,
          currentException: exception,
          seats: seatsFromPlan,
        });
      } else {
        // If availability exception exists, delete it first and then create a new one.
        // NOTE: currently, API does not support update (only deleting and creating)
        this.props.availability
          .onDeleteAvailabilityException({ id, currentException: exception, seats: seatsFromPlan })
          .then(r => {
            const params = { listingId, start, end, seats, currentException: exception };
            this.props.availability.onCreateAvailabilityException(params);
          });
      }
    } else {
      // If there is no existing AvailabilityExceptions, just create a new one
      const params = { listingId, start, end, seats, currentException: exception };
      this.props.availability.onCreateAvailabilityException(params);
    }
  }

  onDateChange(date, seats, reason) {
    // this.setState({ date });

    const { availability, listingAvailability, listingId } = this.props;
    // This component is for day/night based processes. If time-based process is used,
    // you might want to deal with local dates using monthIdString instead of monthIdStringInUTC.

    const { isPast, isInProgress } = dateModifiers(
      date,
      listingAvailability
    );

    if (isPast || isInProgress) {
      // Cannot allow or block a reserved or a past date or inProgress
      return;
    } else {
      const reasonMaybe = reason ? { reason } : {}
      // Block the date (seats = 0)
      // this.onDayAvailabilityChange(date, seats, exceptions);
      availability.onCreateAvailabilityUpdate({ listingId: listingId.uuid, date: date.toISOString().substring(0,10), diff: seats, ...reasonMaybe });
    }
  }

  onFocusChange() {
    // Force the state.focused to always be truthy so that date is always selectable
    this.setState({ focused: true });
  }

  onMonthClick(monthFn) {
    const onMonthChanged = this.props.onMonthChanged;
    this.setState(
      prevState => ({ currentMonth: monthFn(prevState.currentMonth) }),
      () => {
        // Callback function after month has been updated.
        // react-dates component has next and previous months ready (but inivisible).
        // we try to populate those invisible months before user advances there.
        // this.fetchMonthData(monthFn(this.state.currentMonth));

        // If previous fetch for month data failed, try again.
        const monthId = monthIdString(this.state.currentMonth);
        const currentMonthData = this.props.availability.calendar[monthId];
        const { fetchExceptionsError, fetchBookingsError } = currentMonthData || {};
        if (currentMonthData && (fetchExceptionsError || fetchBookingsError)) {
          // this.fetchMonthData(this.state.currentMonth);
        }

        // Call onMonthChanged function if it has been passed in among props.
        if (onMonthChanged) {
          onMonthChanged(monthIdString(this.state.currentMonth));
        }
      }
    );
  }

  render() {
    const {
      className,
      rootClassName,
      listingId,
      availability,
      availabilityPlan,
      listingAvailability,
      onMonthChanged,
      monthFormat,
      listingHasServiceDays,
      ...rest
    } = this.props;

    const { focused, date, currentMonth } = this.state;
    const { clientWidth: width } = this.dayPickerWrapper || { clientWidth: 0 };
    const hasWindow = typeof window !== 'undefined';
    const windowWidth = hasWindow ? window.innerWidth : 0;

    const daySize = dayWidth(width, windowWidth);
    const calendarGridWidth = daySize * TABLE_COLUMNS + TABLE_BORDER;

    const calendar = availability.calendar;
    const { exceptions = [], bookings = [] } = calendar[monthIdStringInUTC(date)] || {};
    const currentMonthData = calendar[monthIdString(currentMonth)];
    const {
      fetchExceptionsInProgress,
      fetchBookingsInProgress,
      fetchExceptionsError,
      fetchBookingsError,
    } = currentMonthData || {};
    const isMonthDataFetched =
      !isMonthInRange(currentMonth, TODAY_MOMENT, END_OF_RANGE_MOMENT) ||
      (!!currentMonthData && !fetchExceptionsInProgress && !fetchBookingsInProgress);

    const monthName = currentMonth.format('MMMM');
    const classes = classNames(rootClassName || css.root, className);

    return (
      <div
        className={classes}
        ref={c => {
          this.dayPickerWrapper = c;
        }}
      >
        {width > 0 ? (
          <div style={{ width: `${calendarGridWidth}px` }}>
            <DayPickerSingleDateController
              {...rest}
              ref={c => {
                this.dayPicker = c;
              }}
              numberOfMonths={1}
              navPrev={<IconArrowHead direction="left" />}
              navNext={<IconArrowHead direction="right" />}
              weekDayFormat="ddd"
              daySize={daySize}
              renderDayContents={renderDayContents(calendar, availabilityPlan, listingAvailability, listingHasServiceDays)}
              focused={focused}
              date={date}
              onDateChange={(date)=>this.setState({ isSeatsModalOpen: true, date })}
              onFocusChange={this.onFocusChange}
              onPrevMonthClick={() => this.onMonthClick(prevMonthFn)}
              onNextMonthClick={() => this.onMonthClick(nextMonthFn)}
              hideKeyboardShortcutsPanel
              horizontalMonthPadding={9}
              renderMonthElement={({ month }) => (
                <div className={css.monthElement}>
                  <span className={css.monthString}>{month.format(monthFormat)}</span>
                </div>
              )}
            />
          </div>
        ) : null}
        <div className={css.legend} style={{ width: `${calendarGridWidth}px` }}>
          <div className={css.legendRow}>
            <span className={css.legendAvailableColor} />
            <span className={css.legendText}>
              <FormattedMessage id="EditListingAvailabilityForm.availableDay" />
            </span>
          </div>
          <div className={css.legendRow}>
            <span className={css.legendBlockedColor} />
            <span className={css.legendText}>
              <FormattedMessage id="EditListingAvailabilityForm.blockedDay" />
            </span>
          </div>
          <div className={css.legendRow}>
            <span className={css.legendReservedColor} />
            <span className={css.legendText}>
              <FormattedMessage id="EditListingAvailabilityForm.bookedDay" />
            </span>
          </div>
          <div className={css.legendRow}>
            <span className={css.legendPartiallyReservedColor} />
            <span className={css.legendText}>
              <FormattedMessage id="EditListingAvailabilityForm.partiallyBookedDay" />
            </span>
          </div>
          <div className={css.legendRow}>
            <span className={css.legendOverbookingColor} />
            <span className={css.legendText}>
              <FormattedMessage id="EditListingAvailabilityForm.overbooking" />
            </span>
          </div>
        </div>
        
        <Modal
          id="EditSeatsModal"
          containerClassName={css.modalContainer}
          closeButtonClassName={css.closeButton}
          isOpen={this.state.isSeatsModalOpen}
          onClose={()=>this.setState({ isSeatsModalOpen: false, date: null })}
          usePortal
          onManageDisableScrolling={()=>{}}
        >
          <div className={css.searchContainer}>
            {
              date &&
                <EditSeats
                  closeModal={()=>this.setState({ isSeatsModalOpen: false, date: null })}
                  currentSeats={2}
                  date={date}
                  dateData={dateModifiers(date, listingAvailability)}
                  onDateChange={this.onDateChange}
                />
            }
          </div>
        </Modal>
      </div>
    );
  }
}

ManageAvailabilityCalendar.defaultProps = {
  className: null,
  rootClassName: null,

  // day presentation and interaction related props
  renderCalendarDay: undefined,
  renderDayContents: null,
  isDayBlocked: () => false,
  isOutsideRange,
  isDayHighlighted: () => false,
  enableOutsideDays: true,

  // calendar presentation and interaction related props
  orientation: HORIZONTAL_ORIENTATION,
  withPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 2,
  onOutsideClick() {},
  keepOpenOnDateSelect: false,
  renderCalendarInfo: null,
  isRTL: false,

  // navigation related props
  navPrev: null,
  navNext: null,
  onPrevMonthClick() {},
  onNextMonthClick() {},

  // internationalization
  monthFormat: 'MMMM YYYY',
  onMonthChanged: null,
};

ManageAvailabilityCalendar.propTypes = {
  className: string,
  rootClassName: string,
  availability: shape({
    calendar: object.isRequired,
    onFetchAvailabilityExceptions: func.isRequired,
    onFetchBookings: func.isRequired,
    onDeleteAvailabilityException: func.isRequired,
    onCreateAvailabilityException: func.isRequired,
  }).isRequired,
  availabilityPlan: propTypes.availabilityPlan.isRequired,
  onMonthChanged: func,
};

export default ManageAvailabilityCalendar;
