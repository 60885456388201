import * as custom from './marketplace-custom-config.js';
import defaultLocationSearches from './default-location-searches';
import { defaultMCC, stripePublishableKey, stripeCountryDetails } from './stripe-config';
import { currencyConfiguration } from './currency-config';

const env = process.env.REACT_APP_ENV;
const dev = process.env.REACT_APP_ENV === 'development';
const lang = process.env.REACT_APP_LANG;

// If you want to change the language, remember to also change the
// locale data and the messages in the app.js file.
const locale = lang || 'pl';

const i18n = {
  /*
    0: Sunday
    1: Monday
    ...
    6: Saturday
  */
  firstDayOfWeek: 1,
};

// Should search results be ordered by distance to origin.
// NOTE: If this is set to true add parameter 'origin' to every location in default-location-searches.js
// Without the 'origin' parameter, search will not work correctly
// NOTE: Keyword search and ordering search results by distance can't be used at the same time. You can turn keyword
// search off by changing the keywordFilterConfig parameter active to false in marketplace-custom-config.js
const sortSearchByDistance = false;

// API supports custom processes to be used in booking process.
// We need to specify it when we are initiating a new order
// (or fetching price information by calling 'initiateSpeculative' endpoint).
//
// In a way, 'processAlias' defines which transaction process (or processes)
// this particular web application is able to handle.
const bookingProcessAlias = 'preauth-nightly-booking/release-1';

// The transaction line item code for the main unit type in bookings.
//
// Possible values: ['line-item/night', 'line-item/day', 'line-item/units';]
//
// Note: translations will use different translation keys for night, day or unit
// depending on the value chosen.
const bookingUnitType = 'day';

// Should the application fetch available time slots (currently defined as
// start and end dates) to be shown on listing page.
// const enableAvailability = process.env.REACT_APP_AVAILABILITY_ENABLED === 'true';
const enableAvailability = true;

// A maximum number of days forwards during which a booking can be made.
// This is limited due to Stripe holding funds up to 90 days from the
// moment they are charged. Also note that available time slots can only
// be fetched for 180 days in the future.
const dayCountAvailableForBooking = 170;

// To pass environment variables to the client app in the build
// script, react-scripts (and the sharetribe-scripts fork of
// react-scripts) require using the REACT_APP_ prefix to avoid
// exposing server secrets to the client side.
const sdkClientId = process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID;
const sdkBaseUrl = process.env.REACT_APP_SHARETRIBE_SDK_BASE_URL;
const sdkTransitVerbose = process.env.REACT_APP_SHARETRIBE_SDK_TRANSIT_VERBOSE === 'true';

const currency = process.env.REACT_APP_SHARETRIBE_MARKETPLACE_CURRENCY;

// Currency formatting options.
// See: https://github.com/yahoo/react-intl/wiki/API#formatnumber
const currencyConfig = currencyConfiguration(currency);

// Listing minimum price in currency sub units, e.g. cents.
// 0 means no restriction to the price
const listingMinimumPriceSubUnits = 0;

// Sentry DSN (Data Source Name), a client key for authenticating calls to Sentry
const sentryDsn = process.env.REACT_APP_SENTRY_DSN;

// If webapp is using SSL (i.e. it's behind 'https' protocol)
const usingSSL = process.env.REACT_APP_SHARETRIBE_USING_SSL === 'true';

// Address information is used in SEO schema for Organization (http://schema.org/PostalAddress)
const addressCountry = 'PL';
const addressRegion = 'Warsaw';
const postalCode = '00100';
const streetAddress = 'Geodezyjna 74';

// Canonical root url is needed in social media sharing and SEO optimization purposes.
const canonicalRootURL = process.env.REACT_APP_CANONICAL_ROOT_URL;

// Site title is needed in meta tags (bots and social media sharing reads those)
const siteTitle = 'Decathlon Rent';

// Twitter handle is needed in meta tags (twitter:site). Start it with '@' character
const siteTwitterHandle = null;

// Instagram page is used in SEO schema (http://schema.org/Organization)
const siteInstagramPage = null;

// Facebook page is used in SEO schema (http://schema.org/Organization)
const siteFB = [
  {countryId: 'pl', link: 'https://www.facebook.com/decathlonrent/'},
  {countryId: 'hu', link: 'https://www.facebook.com/decathlonmagyarorszag'},
  {countryId: 'cz', link: 'https://www.facebook.com/Decathlon.cz/?locale=cs_CZ'},
];

const langIdForCountryId = [
  {countryId: 'cz', langId: 'cs', countryLimit: [ 'PL', 'HU', 'LT', 'CZ', 'LV']}, 
  {countryId: 'ee', langId: 'et', countryLimit: [ 'PL', 'EE', 'LT', 'CZ', 'LV']},
  {countryId: 'pl', langId: 'pl', countryLimit: [ 'PL', 'HU', 'LT', 'CZ', 'LV']},
  {countryId: 'hu', langId: 'hu', countryLimit: [ 'PL', 'HU', 'LT', 'CZ', 'LV']},
  {countryId: 'lt', langId: 'lt', countryLimit: [ 'PL', 'EE', 'LT', 'CZ', 'LV']},
  {countryId: 'lv', langId: 'lv', countryLimit: [ 'PL', 'EE', 'LT', 'CZ', 'LV']},
  {countryId: 'en', langId: 'en', countryLimit: [ 'PL', 'HU', 'LT', 'CZ', 'LV']},
  {countryId: 'sk', langId: 'sk', countryLimit: [ 'PL', 'CZ', 'SK', 'HU', 'LT']},
]

const defaultAddress = {
  hu: { address: 'Magyarország', bounds: '48.583333,22.9,45.75,16.1' },
  pl: { address: 'Polska', bounds: '54.9054761,24.1458931,49.002025,14.1228641' },
  lt: { address: 'Lietuva', bounds: '57.33552157,25.64131462,52.83275719,20.89522087' },
  cz: { address: 'Česká republika', bounds: '52.54904037,18.47034983,47.1965123,12.93324045' },
  lv: { address: 'Rīga, Latvija', bounds: '57.23945983,24.49227362,56.60993069,23.85094671' },
  ee: { address: 'Tallinn, Eesti', bounds: '59.64198683,25.05528371,59.2060887,24.46751516' },
};

const fb = siteFB.find(({countryId}) => countryId === process.env.REACT_APP_COUNTRY_ID );
const siteFacebookPage = fb ? fb.link : siteFB[0].link;

// Facebook counts shares with app or page associated by this id
// Currently it is unset, but you can read more about fb:app_id from
// https://developers.facebook.com/docs/sharing/webmasters#basic
// You should create one to track social sharing in Facebook
const facebookAppId = null;

const maps = {
  mapboxAccessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  googleMapsAPIKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,

  // The location search input can be configured to show default
  // searches when the user focuses on the input and hasn't yet typed
  // anything. This reduces typing and avoids too many Geolocation API
  // calls for common searches.
  search: {
    // When enabled, the first suggestion is "Current location" that
    // uses the browser Geolocation API to query the user's current
    // location.
    // suggestCurrentLocation: process.env.REACT_APP_DEFAULT_SEARCHES_ENABLED === 'true',
    suggestCurrentLocation: false,

    // Distance in meters for calculating the bounding box around the
    // current location.
    currentLocationBoundsDistance: 1000,

    // Example location can be edited in the
    // `default-location-searches.js` file.
    defaults:
      process.env.REACT_APP_DEFAULT_SEARCHES_ENABLED === 'true' ? defaultLocationSearches : [],

    // Limit location autocomplete to a one or more countries
    // using ISO 3166 alpha 2 country codes separated by commas.
    // If you want to limit the autocomplete, uncomment this value:
    countryLimit: langIdForCountryId.find(c => c.countryId === process.env.REACT_APP_COUNTRY_ID).countryLimit,
    countryBounds: defaultAddress[process.env.REACT_APP_COUNTRY_ID],
    defaultBounds: defaultAddress,
  },

  // When fuzzy locations are enabled, coordinates on maps are
  // obfuscated randomly around the actual location.
  //
  // NOTE: This only hides the locations in the UI level, the actual
  // coordinates are still accessible in the HTTP requests and the
  // Redux store.
  fuzzy: {
    enabled: false,

    // Amount of maximum offset in meters that is applied to obfuscate
    // the original coordinates. The actual value is random, but the
    // obfuscated coordinates are withing a circle that has the same
    // radius as the offset.
    offset: 500,

    // Default zoom level when showing a single circle on a Map. Should
    // be small enough so the whole circle fits in.
    defaultZoomLevel: 13,

    // Color of the circle on the Map component.
    circleColor: '#c0392b',
  },

  // Custom marker image to use in the Map component.
  //
  // NOTE: Not used if fuzzy locations are enabled.
  customMarker: {
    enabled: false,

    // Publicly accessible URL for the custom marker image.
    //
    // The easiest place is /public/static/icons/ folder, but then the
    // marker image is not available while developing through
    // localhost.
    url: encodeURI(`${canonicalRootURL}/static/icons/map-marker-32x32.png`),

    // Dimensions of the marker image.
    width: 32,
    height: 32,

    // Position to anchor the image in relation to the coordinates,
    // ignored when using Mapbox.
    anchorX: 16,
    anchorY: 32,
  },
};

const countryId = ['pl', 'cz', 'hu', 'lv', 'lt', 'ee'];

const countryNames = [
  { countryId: 'pl', country: 'ConsoleTransactionPage.countryNames.PL' },
  { countryId: 'hu', country: 'ConsoleTransactionPage.countryNames.HU' },
  { countryId: 'cz', country: 'ConsoleTransactionPage.countryNames.CZ' },
  { countryId: 'lt', country: 'ConsoleTransactionPage.countryNames.LT' },
  { countryId: 'lv', country: 'ConsoleTransactionPage.countryNames.LV' },
  { countryId: 'ee', country: 'ConsoleTransactionPage.countryNames.EE' },
];

const permissions = [
  { name: 'owner', value: 'owner' },
  { name: 'employee', value: 'employee' },
];

// NOTE: only expose configuration that should be visible in the
// client side, don't add any server secrets in this file.
const config = {
  env,
  dev,
  locale,
  bookingProcessAlias,
  bookingUnitType,
  enableAvailability,
  dayCountAvailableForBooking,
  i18n,
  sdk: {
    clientId: sdkClientId,
    baseUrl: sdkBaseUrl,
    transitVerbose: sdkTransitVerbose,
  },
  sortSearchByDistance,
  currency,
  currencyConfig,
  listingMinimumPriceSubUnits,
  stripe: {
    defaultMCC: defaultMCC,
    publishableKey: stripePublishableKey,
    supportedCountries: stripeCountryDetails,
  },
  canonicalRootURL,
  address: {
    addressCountry,
    addressRegion,
    postalCode,
    streetAddress,
  },
  siteTitle,
  siteFacebookPage,
  siteInstagramPage,
  siteTwitterHandle,
  facebookAppId,
  sentryDsn,
  usingSSL,
  maps,
  custom,
  countryId,
  countryNames,
  permissions,
  langIdForCountryId,
  testProviderFilterOn: process.env.REACT_APP_COUNTRY_ID === 'cz'
};

export default config;
