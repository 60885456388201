import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Controller, useForm } from 'react-hook-form';
import { func, string, arrayOf, shape } from 'prop-types';
import classNames from 'classnames';
import { Checkbox } from '../../components';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import {
  parse,
  decodeLatLng,
  encodeLatLng,
  encodeLatLngBounds,
  decodeLatLngBounds,
  stringify,
} from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import useLocalStorage from '../../hooks/useLocalStorage';
import businessCategoryConfig from '../../business-category-config';
import { IconSearchSecond, ValidationError } from '../../components';
import Geocoder from '../../components/LocationAutocompleteInput/GeocoderGoogleMaps';
import AutocompleteLocation from './AutocompleteLocation/AutocompleteLocation';
import AutocompleteListing from './AutocompleteListing/AutocompleteListing';
import categoryRoutesConfiguration from '../../categoryRoutes';

import css from './MainFiltersForm.css';

const NUMBER_OF_DEGREES_FROM_ORIGIN = 0.003;
const SEARCH_PAGE = 'SearchPage';
const FORM_FIELDS = {
  KEYWORDS: 'keywordsField',
  LOCATION: 'locationField',
  PROVIDER: 'providerField'
};
const categoryRoutes = categoryRoutesConfiguration();

const configCountryId = process.env.REACT_APP_COUNTRY_ID;

const MainFiltersForm = ({
  intl,
  location,
  history,
  categoriesSuggestions,
  formClassName,
  listingInputClassName,
  listingInputFocusClassName,
  locationWrapperClassName,
  locationInputClassName,
  locationInputFocusClassName,
  buttonSearchClassName,
  formContainerClassName,
  searchIconClassName,
  searchTextClassName,
  predictionsClassName,
  suggestionsWrapperClassName,
  storeFilterClassName,
  buttonText,
  onModalClose,
  isMobileModalOpen,
  wrapperClassName,
  providers = [],
  displayProviderFilter,
  pageName
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isModalOpen]);

  const { address, origin, keywords, category, businessCategory, page, exact, isLocation, sort, providerId, ...rest } = parse(
    location.search
  );
  const selectedStoreNumber = providerId ? providerId.split(",").length : 0;
  const filterClasses = classNames(css.filtersBtn, { [css.borderFilters]: !!selectedStoreNumber });
  const subcategory = businessCategory && businessCategoryConfig.find(({ key }) => key === businessCategory);
  const textSubcategory = subcategory && intl.formatMessage({ id: subcategory.translation });
  const [lastSearches, setLastSearches] = useLocalStorage('last-searches', []);
  const formClasses = classNames(formClassName, css.formRoot);
  const formContainerClasses = classNames(formContainerClassName, css.formContainer);
  const listingInputClasses = classNames(listingInputClassName, css.listingInput);
  const listingInputFocusClasses = classNames(listingInputFocusClassName, css.listingInputFocus);
  const locationWrapperClasses = classNames(locationWrapperClassName, css.locationInput);
  const locationInputClasses = classNames(locationInputClassName, css.customSelectInput);
  const locationInputFocusClasses = classNames(locationInputFocusClassName, css.customSelectInputFocus);
  const buttonSearchClasses = classNames(buttonSearchClassName, css.btnSearch);
  const searchIconClasses = classNames(searchIconClassName, css.searchIcon);
  const searchTextClasses = classNames(searchTextClassName, css.searchText);
  const predictionsClasses = classNames(predictionsClassName, css.predictionsRoot);
  const storeFilterClasses = classNames(storeFilterClassName,  css.filtersWrapper);

  const keywordRef = useRef();
  const _geocoderRef = useRef();
  const locationRef = useRef();

  const initialValues = {
    locationField: {
      search: address || '',
      selectedPlace: {
        address: address || '',
        origin: origin ? decodeLatLng(origin) : {},
        bounds: location.state?.bounds || {},
      },
      isUserLocation: !!isLocation,
    },
    keywordsField: {
      keywords: !!exact ? (businessCategory ? textSubcategory : '') : (keywords || ""),
      category: category || '',
      subcategory: businessCategory || '',
      exact: !!exact,
    },
    providerField: providerId || '',
  };

  const { handleSubmit, control, setValue, getValues, reset } = useForm({
    defaultValues: initialValues,
  });

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setValue('providerField', providerId)
  }

  const handleCloseModal = () => setIsModalOpen(false);

  const getGeocoder = () => {
    if (!_geocoderRef.current) {
      _geocoderRef.current = new Geocoder();
    }
    return _geocoderRef.current;
  };

  const setLocationField = (locationValue, originValue, boundsValue) =>
    setValue(FORM_FIELDS.LOCATION, {
      search: locationValue || '',
      selectedPlace: {
        address: locationValue || '',
        origin: originValue ? decodeLatLng(originValue) : {},
        bounds: decodeLatLngBounds(boundsValue) || {},
      },
      isUserLocation: false,
    });

  const onSubmit = async data => {
    const { locationField, keywordsField, providerField } = data;
    const { selectedPlace, predictions, isUserLocation } = locationField || {};

    let firstPrediction;
    if (!selectedPlace && predictions && predictions[0]) {
      firstPrediction = await getGeocoder().getPlaceDetails(predictions[0]);
    }

    const { address: addressPrediction, bounds, origin: originPrediction } =
      selectedPlace || firstPrediction || {};

    const originMaybe =
      addressPrediction === address
        ? { origin }
        : originPrediction && !!Object.keys(originPrediction).length
          ? { origin: encodeLatLng(originPrediction) }
          : {};

    if (selectedPlace?.address || keywordsField.keywords) {
      setLastSearches(prevState => {
        const duplicateLastSearch = prevState.find(
          item =>
            keywordsField.keywords === item.keyword &&
            keywordsField.category === item.category &&
            keywordsField.subcategory === item.subcategory &&
            keywordsField.exact === item.exact &&
            addressPrediction === item.location
        );

        if (duplicateLastSearch) return prevState;

        const originValue =
          addressPrediction === address
            ? origin
            : originPrediction && !!Object.keys(originPrediction).length
              ? encodeLatLng(originPrediction)
              : '';

        const lat = originValue && decodeLatLng(originValue).lat;
        const lng = originValue && decodeLatLng(originValue).lng;
        const calculatedBounds = originValue
          ? `${lat + NUMBER_OF_DEGREES_FROM_ORIGIN},${lng + NUMBER_OF_DEGREES_FROM_ORIGIN},${lat -
          NUMBER_OF_DEGREES_FROM_ORIGIN},${lng - NUMBER_OF_DEGREES_FROM_ORIGIN}`
          : '';
        const boundsValue =
          bounds && !!Object.keys(bounds).length ? encodeLatLngBounds(bounds) : calculatedBounds;

        const value = {
          keyword: keywordsField.keywords,
          category: keywordsField.category,
          subcategory: keywordsField.subcategory,
          location: addressPrediction,
          origin: originValue,
          bounds: boundsValue,
          exact: keywordsField.exact,
        };

        const searches = prevState.filter((item, index) => index < 4);
        return prevState.length < 5 ? [value, ...prevState] : [value, ...searches];
      });
    }

    const businessCategoryRoute = keywordsField?.exact && keywordsField?.subcategory
      ? categoryRoutes.find(route => route.key === keywordsField?.subcategory ) : null;
    const routeName = !!businessCategoryRoute
      ? businessCategoryRoute.name
      : ( pageName !== SEARCH_PAGE && !keywordsField?.subcategory ) ? pageName : SEARCH_PAGE;
    const routes = !!businessCategoryRoute ? categoryRoutes : routeConfiguration();

    const addressMaybe = addressPrediction ? { address: addressPrediction } : {};
    const categoryMaybe = keywordsField.category ? { category: keywordsField.category } : {};
    const businessCategoryMaybe = keywordsField.subcategory && !businessCategoryRoute
      ? { businessCategory: keywordsField.subcategory }
      : {};
    const keywordsMaybe = keywordsField.keywords && !businessCategoryRoute
      ? { keywords: keywordsField.keywords } : {};
    const userLocationMaybe = isUserLocation ? { isLocation: true } : {};
    const exactMaybe = keywordsField?.exact && !businessCategoryRoute ? { exact: true } : {};
    const sortMaybe = (keywordsField?.exact && sort === 'relevance') || !keywordsField.keywords || keywordsField?.exact !== exact ? {} : { sort };
    const providerMaybe = providerField ? { providerId: providerField } : {};

    const searchParams = {
      ...rest,
      ...originMaybe,
      ...addressMaybe,
      ...categoryMaybe,
      ...businessCategoryMaybe,
      ...keywordsMaybe,
      ...userLocationMaybe,
      ...exactMaybe,
      ...sortMaybe,
      ...providerMaybe
    };

    history.push({
      pathname: createResourceLocatorString(SEARCH_PAGE, routeConfiguration(), {}, {}),
      search: stringify(searchParams),
      state: { bounds },
    });

    handleCloseModal();
  };

  const onKeywordsFocus = () => {
    !keywordRef.current.value && keywordRef.current.focus();
  };

  const onLocationFocus = () => {
    !locationRef.current.value && locationRef.current.focus();
  };

  const handleBlur = () => {
    const currentLocationField = getValues(FORM_FIELDS.LOCATION);
    const firstPrediction =
      !!currentLocationField?.predictions?.length &&
      currentLocationField?.predictions[0].description;

    if (firstPrediction && firstPrediction !== currentLocationField.search) {
      setValue(FORM_FIELDS.LOCATION, {
        ...currentLocationField,
        search: firstPrediction,
      });
    }
  };

  useEffect(() => {
    if (isMobileModalOpen) keywordRef.current.focus();

    const locationInput = document.getElementById('locationInput');
    locationInput && locationInput.addEventListener('blur', handleBlur);

    return () => {
      locationInput && locationInput.removeEventListener('blur', handleBlur);
    };
  }, []);

  const filteredProvidersUnsorted = providers
    .filter(provider =>
      provider.attributes.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

  // Grupujemy providerów wg countryId
  const providersByCountry = filteredProvidersUnsorted.reduce((acc, provider) => {
    const cid = provider.attributes.countryId || 'unknown';
    if (!acc[cid]) acc[cid] = [];
    acc[cid].push(provider);
    return acc;
  }, {});

  // Sortujemy najpierw kraj z configCountryId, potem resztę alfabetycznie
  const countryIds = Object.keys(providersByCountry);
  countryIds.sort((a, b) => {
    if (a === configCountryId) return -1;
    if (b === configCountryId) return 1;
    return a.localeCompare(b);
  });

  // Spłaszczamy posortowane grupy w jedną listę
  const filteredProviders = countryIds.flatMap(cid => providersByCountry[cid]);

  const clearProviderFilter = () => {
    const searchParams = {
      ...parse(location.search),
      providerId: undefined
    };

    history.push(createResourceLocatorString(pageName, routeConfiguration(), {}, searchParams));

    reset({ providerField: '' });
    handleCloseModal();
  };

  const onProvidersSubmit = e => {
    const {
      providerField,
    } = getValues();

    const providerMaybe = providerField ? { providerId: providerField } : {};
    const { providerId, ...restParams } = parse(location.search);

    const searchParams = {
      ...restParams,
      ...providerMaybe,
    };

    history.push(createResourceLocatorString(pageName, routeConfiguration(), {}, searchParams));

    handleCloseModal();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={formClasses}>
      <div className={formContainerClasses}>
        <Controller
          control={control}
          name={FORM_FIELDS.KEYWORDS}
          render={({ field: { onChange, value } }) => (
            <div>
              <AutocompleteListing
                inputClassName={listingInputClasses}
                inputFocusClassName={listingInputFocusClasses}
                value={value}
                onChange={onChange}
                placeholder={intl.formatMessage({ id: 'SearchKeyword.search' })}
                suggestions={categoriesSuggestions}
                ref={keywordRef}
                onLocationFocus={onLocationFocus}
                setLastSearches={setLastSearches}
                lastSearches={lastSearches}
                setLocationField={setLocationField}
                intl={intl}
                suggestionsWrapperClassName={suggestionsWrapperClassName}
                wrapperClassName={wrapperClassName}
              />
            </div>
          )}
        />
        <Controller
          control={control}
          name={FORM_FIELDS.LOCATION}
          render={({
            field: { onChange, onBlur, value, name },
            fieldState: { isTouched },
            formState: { isValid },
          }) => (
            <div>
              <AutocompleteLocation
                className={locationWrapperClasses}
                inputClassName={locationInputClasses}
                inputFocusClassName={locationInputFocusClasses}
                iconClassName={css.customSelectInputIcon}
                predictionsClassName={predictionsClasses}
                validClassName={css.validLocation}
                placeholder={intl.formatMessage({ id: 'MainFilters.searchCity' })}
                name={name}
                input={{
                  name,
                  onBlur,
                  onChange,
                  onFocus: () => locationRef.current.focus(),
                  value,
                }}
                useDefaultPredictions={false}
                citiesOnly
                ref={locationRef}
                setLatestSearches={setLastSearches}
                latestSearches={lastSearches}
                onKeywordsFocus={onKeywordsFocus}
                intl={intl}
              />
              <ValidationError
                fieldMeta={{
                  valid: isValid,
                  touched: isTouched,
                }}
              />
            </div>
          )}
        />
        <button className={buttonSearchClasses} type="submit">
          <IconSearchSecond className={searchIconClasses} />
          {buttonText && <span className={searchTextClasses}>{buttonText}</span>}
        </button>
        {
          displayProviderFilter &&
          <div className={storeFilterClasses}>
            {!!selectedStoreNumber && <div className={css.filterDot}>{selectedStoreNumber}</div>}
            <div className={filterClasses} onClick={handleOpenModal}>
              <FormattedMessage id="FilterModal.provider" />
            </div>
          </div>
        }
        {isModalOpen && (
          <Modal onClose={handleCloseModal}>
            <div className={css.modalContent}>
              <div className={css.modalHeader}>
                <h2>
                  <FormattedMessage id="FilterModal.provider" defaultMessage="Providers" />
                </h2>
                <input
                  type="text"
                  placeholder={intl.formatMessage({ id: 'MainFiltersForm.providerPlaceholder' })}
                  value={searchQuery}
                  onChange={e => setSearchQuery(e.target.value)}
                  className={css.searchInput}
                />
                <button className={css.closeButton} onClick={handleCloseModal} aria-label="Close">
                  &times;
                </button>
              </div>

              <Controller
                control={control}
                name={FORM_FIELDS.PROVIDER}
                render={({ field: { onChange, value } }) => {
                  const selectedProviders = value ? value.split(',') : [];
                  let lastCountryId = null;

                  return (
                    <div className={css.providerContainer}>
                      {filteredProviders.map(({ attributes: { name, countryId }, id: { uuid }, distance }) => {
                        const isSelected = selectedProviders.includes(uuid);
                        const showSeparator = countryId !== lastCountryId && lastCountryId !== null;
                        lastCountryId = countryId;

                        return (
                          <React.Fragment key={uuid}>
                            {showSeparator && (
                              <div className={css.countrySeparator}>
                                <span><FormattedMessage id={`General-${countryId}`} /></span>
                              </div>
                            )}
                            <div className={css.itemContainer}>
                              <Checkbox
                                label={name}
                                checked={isSelected}
                                onChange={() => {
                                  let updatedProviders;
                                  if (isSelected) {
                                    updatedProviders = selectedProviders.filter(id => id !== uuid);
                                  } else {
                                    updatedProviders = [...selectedProviders, uuid];
                                  }
                                  onChange(updatedProviders.join(','));
                                }}
                                size="large"
                              />
                              {(distance || distance === 0) && (
                                <span className={css.distanceText}>{distance} km</span>
                              )}
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  );
                }}
              />
              <div className={css.modalFooter}>
                <a className={css.clearLink} onClick={clearProviderFilter}>
                  <FormattedMessage id="FilterModal.clear" defaultMessage="Wyczyść" />
                </a>
                <button className={css.btnSearchPro} onClick={onProvidersSubmit}>
                  <FormattedMessage id="FilterModal.showResults" defaultMessage="Zatwierdź" />
                </button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </form>
  );
};

MainFiltersForm.defaultProps = {
  formClassName: null,
  listingInputClassName: null,
  locationWrapperClassName: null,
  locationInputClassName: null,
  buttonSearchClassName: null,
  formContainerClassName: null,
  searchIconClassName: null,
  searchTextClassName: null,
  predictionsClassName: null,
  suggestionsWrapperClassName: null,
  buttonText: null,
  onModalClose: () => { },
  pageName: 'SearchPage'
};

MainFiltersForm.propTypes = {
  intl: intlShape.isRequired,
  categoriesSuggestions: arrayOf(propTypes.categoriesSuggestions).isRequired,
  formClassName: string,
  listingInputClassName: string,
  locationWrapperClassName: string,
  locationInputClassName: string,
  buttonSearchClassName: string,
  formContainerClassName: string,
  searchIconClassName: string,
  searchTextClassName: string,
  predictionsClassName: string,
  suggestionsWrapperClassName: string,
  buttonText: string,
  onModalClose: func,
  location: shape({ search: string }).isRequired,
  history: shape({ push: func.isRequired }).isRequired,
  pageName: string,
};

const Modal = ({ children, onClose }) => {
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [onClose]);

  return ReactDOM.createPortal(
    <div className={css.modalBackdrop} onClick={onClose}>
      <div className={css.modalContainer} onClick={e => e.stopPropagation()}>
        {children}
      </div>
    </div>,
    document.body
  );
};

export default MainFiltersForm;
